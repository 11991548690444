import "./index.css"
import FixedExample from "./fixed-example";

function Scroll() {
  return (
    <>
      {/* 固定高度 */}
      <FixedExample />
    </>
  );
}

export default Scroll